import React from 'react'
import { Container, Grid, Typography } from '@mui/material'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import ArticleCard from '../components/article-card'

const IndexPage = ({ data }: any) => {
    const {
        allBlogJson: { edges: allBlogEdges },
    } = data

    const articles = allBlogEdges.map((item: any) => {
        return {
            ...item.node.allArticleJson[0],
            blog: {
                ...item.node,
            },
        }
    })

    return (
        <Layout>
            <Container sx={{ pt: { xs: 3, sm: 6 } }}>
                <Typography variant="h1" sx={{ mb: 2, fontWeight: 'bold' }}>
                    新着記事
                </Typography>
                <Grid container columnSpacing={3} rowSpacing={6}>
                    {articles.map((article: any) => {
                        return (
                            <Grid key={article.id} item xs={12} sm={6} md={4}>
                                <ArticleCard article={article} blog={article.blog} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query PageQuery {
        allBlogJson(sort: { fields: articles___created, order: DESC }, limit: 30) {
            edges {
                node {
                    id
                    blog_id
                    site
                    blog_url
                    feed_url
                    category
                    tags
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: FIXED
                                width: 96
                                height: 54
                                transformOptions: { cropFocus: CENTER }
                                quality: 90
                            )
                        }
                    }
                    allArticleJson(limit: 1, sort: { fields: created, order: DESC }) {
                        id
                        title
                        description
                        url
                        image
                        content
                        categories
                        created
                        published
                        localImage {
                            publicURL
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FIXED
                                    width: 320
                                    height: 180
                                    transformOptions: { cropFocus: CENTER }
                                    quality: 90
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
